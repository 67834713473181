export const INITIAL_MOVES = 2

export const KEYS = {
  LEFT: 'ArrowLeft',
  RIGHT: 'ArrowRight',
  ARROW_DOWN: 'ArrowDown',
  ARROW_UP: 'ArrowUp',
  KEY_W: 'KeyW',
  KEY_A: 'KeyA',
  KEY_S: 'KeyS',
  KEY_D: 'KeyD',
  KEY_N: 'KeyN',
  KEY_G: 'KeyG',
  KEY_T: 'KeyT',
  KEY_P: 'KeyP',
  KEY_F: 'KeyF',
}
